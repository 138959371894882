import React, { useEffect, useState } from 'react';
import Error from '../components/Error';
import { useUser } from './Authenticate';
import { Outlet } from 'react-router-dom';

const AuthorizationProvider = props => {
    const { role } = props;
    const [content, setContent] = useState();
    const user = useUser();
     //console.log("role", user);
     //console.log("props", props);

    useEffect(() => {
        if (user.role === role) {
            setContent(<Outlet />);
        } else {
            setContent(
                <Error
                    error='You are not an employee, you cannot access this page.'
                    errorCode={403}
                />
            );
        }
    }, [role, user.role]);

    return content;
};

export default AuthorizationProvider;
